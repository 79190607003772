.home-child,
.home-item {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-chocolate-200);
  width: 1440px;
  height: 48px;
}
.home-item {
  top: 10px;
  left: 1133px;
  background-color: var(--color-gainsboro-200);
  width: 1px;
  height: 29px;
}
.email-xyzgmailcom,
.phone-00000000 {
  position: absolute;
  top: 0;
  left: 29px;
  font-weight: 600;
}
.phone-00000000 {
  left: 269px;
}
.vector-icon {
  position: absolute;
  height: 66.67%;
  width: 4.82%;
  top: 20.83%;
  right: 95.18%;
  bottom: 12.5%;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.email-xyzgmailcom-parent {
  position: absolute;
  top: 13px;
  left: 899px;
  width: 415px;
  height: 24px;
  font-size: var(--heading-8-size);
  color: var(--color-white);
}
.vector-icon1 {
  position: absolute;
  height: 0.2%;
  width: 1.11%;
  top: 0.26%;
  right: 19.31%;
  bottom: 99.54%;
  left: 79.58%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.home-inner,
.rectangle-div {
  position: absolute;
  top: 151px;
  left: 0;
  background-color: var(--color-white);
  width: 1440px;
  height: 582px;
}
.rectangle-div {
  top: 713px;
  background-color: var(--color-whitesmoke-300);
  height: 788px;
  display: none;
}
.ellipse-div,
.home-child1 {
  position: absolute;
  top: 1501px;
  left: 0;
  background-color: var(--color-white);
  width: 1440px;
  height: 560px;
}
.ellipse-div {
  top: -52px;
  left: 960px;
  border-radius: 50%;
  background-color: var(--color-chocolate-300);
  width: 901px;
  height: 901px;
}
.go-wherever {
  margin: 0;
}
.go-wherever-whenever-container {
  position: absolute;
  top: 297px;
  left: 149px;
  font-size: 63px;
  letter-spacing: 0.02em;
  line-height: 72px;
  display: inline-block;
  width: 464px;
}
.group-child {
  position: absolute;
  top: 47px;
  left: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  background-color: var(--color-white);
  width: 1440px;
  height: 84px;
}
.logo-design-01-1-icon {
  position: absolute;
  top: 0;
  left: 110px;
  width: 177px;
  height: 177px;
  overflow: hidden;
}
.airport,
.home1 {
  position: absolute;
  top: 82px;
  left: 461px;
  font-weight: 600;
  display: inline-block;
  width: 63px;
  height: 24px;
}
.airport {
  left: 557px;
}
.special-offer {
  left: 653px;
}
.about-us,
.blogs,
.contact-us,
.special-offer {
  position: absolute;
  top: 82px;
  font-weight: 600;
}
.blogs {
  left: 790px;
  display: inline-block;
  width: 63px;
  height: 24px;
}
.about-us,
.contact-us {
  left: 886px;
}
.contact-us {
  top: 80px;
  left: 993px;
}
.group-item {
  position: absolute;
  top: 65px;
  left: 1166px;
  border-radius: var(--br-3xs);
  background-color: var(--color-black);
  width: 150px;
  height: 50px;
}
.mdiuser-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 27px;
  height: 27px;
  overflow: hidden;
}
.login {
  position: absolute;
  top: 2px;
  left: 35px;
  font-weight: 600;
  display: inline-block;
  width: 63px;
  height: 24px;
}
.mdiuser-parent {
  position: absolute;
  top: 76px;
  left: 1192px;
  width: 98px;
  height: 27px;
  color: var(--color-white);
}
.rectangle-parent {
  position: absolute;
  top: -1px;
  left: 0;
  width: 1440px;
  height: 177px;
  font-size: var(--heading-8-size);
}
.cabbx-is-the,
.lorem-ipsum-is {
  position: absolute;
  left: 149px;
  letter-spacing: 0.02em;
}
.cabbx-is-the {
  top: 458px;
  font-size: var(--font-size-xl);
  display: inline-block;
  width: 504px;
}
.lorem-ipsum-is {
  top: 258px;
  font-size: var(--font-size-3xl);
  line-height: 39px;
  font-weight: 500;
}
.group-inner {
  position: absolute;
  top: 0;
  left: 214.5px;
  border-radius: var(--br-3xs);
  background-color: var(--color-chocolate-200);
  width: 214.5px;
  height: 50px;
  transform: rotate(180deg);
  transform-origin: 0 0;
}
.learn-more {
  position: absolute;
  top: 0.1px;
  left: 0;
  letter-spacing: 0.02em;
  font-weight: 600;
  display: inline-block;
  width: 92px;
  height: 17px;
}
.tablerarrow-up-icon {
  position: absolute;
  top: 0;
  left: 94px;
  width: 22px;
  height: 22px;
  overflow: hidden;
  object-fit: contain;
}
.learn-more-parent {
  position: absolute;
  top: 14px;
  left: 49.5px;
  width: 116px;
  height: 22px;
}
.group-child1,
.rectangle-group {
  position: absolute;
  width: 214.5px;
  height: 50px;
}
.rectangle-group {
  top: 565px;
  left: 149.5px;
  text-align: center;
  font-size: var(--font-size-mini);
  color: var(--color-white);
}
.group-child1 {
  top: 0;
  left: 214.5px;
  border-radius: var(--br-3xs);
  background-color: var(--color-black);
  transform: rotate(180deg);
  transform-origin: 0 0;
}
.see-more {
  position: absolute;
  top: 0;
  left: 0;
  letter-spacing: 0.02em;
  font-weight: 600;
}
.see-more-wrapper {
  position: absolute;
  top: 8px;
  left: 53px;
  width: 109px;
  height: 33px;
}
.rectangle-container {
  position: absolute;
  top: 6010px;
  left: 613px;
  width: 214.5px;
  height: 50px;
  text-align: center;
  font-size: var(--font-size-3xl);
  color: var(--color-white);
}
.lorem-ipsum-is-container {
  position: absolute;
  top: 1170px;
  left: 168px;
  font-size: var(--font-size-25xl);
  letter-spacing: 0.02em;
  line-height: 53px;
  font-weight: 600;
  display: inline-block;
  width: 480px;
  height: 101px;
}
.home-child2 {
  position: absolute;
  top: 688px;
  left: 137px;
  box-shadow: 0 0 37px rgba(0, 0, 0, 0.07);
  border-radius: 50px;
  background-color: var(--color-white);
  width: 1167px;
  height: 379px;
}
.group-child2,
.home-child3 {
  position: absolute;
  top: 754px;
  left: 223px;
  border-radius: var(--br-8xs);
  width: 149px;
  height: 1px;
}
.group-child2 {
  top: 0;
  left: 0;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-3xs);
  background-color: var(--color-black);
  width: 198px;
  height: 40px;
}
.airport-parking {
  position: absolute;
  top: 6px;
  left: 29px;
  letter-spacing: 0.02em;
  font-weight: 500;
}
.group-div {
  left: 0;
  color: var(--color-white);
}
.group-child3,
.group-div,
.rectangle-parent1 {
  position: absolute;
  top: 0;
  width: 198px;
  height: 40px;
}
.group-child3 {
  left: 0;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
}
.rectangle-parent1 {
  left: 231px;
}
.lounge {
  position: absolute;
  top: 6px;
  left: 65px;
  letter-spacing: 0.02em;
  font-weight: 500;
}
.rectangle-parent2 {
  position: absolute;
  top: 0;
  left: 462px;
  width: 198px;
  height: 40px;
}
.airport-transfer {
  position: absolute;
  top: 6px;
  left: 26px;
  letter-spacing: 0.02em;
  font-weight: 500;
}
.group-parent,
.rectangle-parent3 {
  position: absolute;
  top: 0;
  left: 693px;
  width: 198px;
  height: 40px;
}
.group-parent {
  top: 739px;
  left: 275px;
  width: 891px;
  font-size: var(--font-size-lg);
  color: var(--color-darkslategray-400);
}
.group-child6 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-3xs);
  background-color: var(--color-whitesmoke-400);
  width: 428px;
  height: 52px;
}
.promo-code {
  position: absolute;
  top: 13px;
  left: 19.6px;
  letter-spacing: 0.02em;
}
.rectangle-parent4 {
  position: absolute;
  top: 942px;
  left: 283px;
  width: 428px;
  height: 52px;
  font-size: var(--font-size-lg);
  color: var(--color-gray-1000);
}
.group-child7 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-3xs);
  background-color: var(--color-whitesmoke-400);
  width: 439.8px;
  height: 52px;
}
.weuiarrow-outlined-icon {
  position: absolute;
  top: 20.4px;
  left: 391.1px;
  width: 23.5px;
  height: 12.1px;
  overflow: hidden;
  object-fit: contain;
}
.airport1 {
  position: absolute;
  top: 13px;
  left: 32.2px;
  letter-spacing: 0.02em;
  display: inline-block;
  width: 66.5px;
  height: 18px;
}
.group-child8,
.rectangle-parent5 {
  position: absolute;
  top: 0;
  left: 0;
  width: 439.8px;
  height: 52px;
}
.group-child8 {
  border-radius: var(--br-3xs);
  background-color: var(--color-whitesmoke-400);
  width: 436.8px;
}
.drop-of-time {
  position: absolute;
  top: 13px;
  left: 32px;
  letter-spacing: 0.02em;
}
.group-child9,
.rectangle-parent6 {
  position: absolute;
  top: 65px;
  left: 0;
  width: 436.8px;
  height: 52px;
}
.group-child9 {
  top: 0;
  border-radius: var(--br-3xs);
  background-color: var(--color-whitesmoke-400);
  width: 437px;
}
.promo-code1 {
  position: absolute;
  top: 13px;
  left: 20px;
  letter-spacing: 0.02em;
  display: inline-block;
  width: 115.4px;
}
.group-child10,
.rectangle-parent7 {
  position: absolute;
  top: 130px;
  left: 0;
  width: 437px;
  height: 52px;
}
.group-child10 {
  top: 0;
  border-radius: var(--br-3xs);
  background-color: var(--color-chocolate-200);
}
.get-code {
  position: absolute;
  top: 13px;
  left: 173.2px;
  letter-spacing: 0.02em;
  font-weight: 600;
  display: inline-block;
  width: 90.5px;
}
.rectangle-parent8 {
  position: absolute;
  top: 131px;
  left: 457px;
  width: 437px;
  height: 52px;
  color: var(--color-white);
}
.drop-of-time1 {
  position: absolute;
  top: 13px;
  left: 32px;
  letter-spacing: 0.02em;
  display: inline-block;
  width: 115.1px;
}
.rectangle-parent9 {
  position: absolute;
  top: 65px;
  left: 457px;
  width: 437px;
  height: 52px;
}
.weuiarrow-outlined-icon1 {
  position: absolute;
  top: 20.4px;
  left: 388.5px;
  width: 23.3px;
  height: 12.1px;
  overflow: hidden;
  object-fit: contain;
}
.airport2 {
  position: absolute;
  top: 13px;
  left: 32.1px;
  letter-spacing: 0.02em;
  display: inline-block;
  width: 66px;
  height: 18px;
}
.group-container,
.rectangle-parent10 {
  position: absolute;
  top: 0;
  left: 457.2px;
  width: 436.8px;
  height: 52px;
}
.group-container {
  top: 816px;
  left: 273px;
  width: 894px;
  height: 183px;
  font-size: var(--font-size-lg);
  color: var(--color-gray-1000);
}
.fontistodate-icon,
.mingcutetime-line-icon {
  position: absolute;
  top: 891px;
  left: 656px;
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.fontistodate-icon {
  top: 892px;
  left: 1106px;
  width: 22px;
  height: 22px;
}
.group-child13 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 30px;
  background-color: var(--color-white);
  width: 479px;
  height: 288px;
}
.lorem-ipsum-is1 {
  position: absolute;
  top: 159px;
  left: 13px;
  letter-spacing: 0.02em;
  display: inline-block;
  width: 453px;
  height: 90px;
}
.best-price-guarantee {
  position: absolute;
  top: 121px;
  left: 122px;
  font-size: var(--font-size-5xl);
  letter-spacing: 0.02em;
  text-transform: capitalize;
  font-weight: 500;
  font-family: var(--font-roboto);
  text-align: left;
}
.mdishield-tick-icon {
  position: absolute;
  top: 41px;
  left: 210px;
  width: 59px;
  height: 59px;
  overflow: hidden;
}
.rectangle-parent11 {
  position: absolute;
  top: 1132px;
  left: 741px;
  width: 479px;
  height: 288px;
  text-align: center;
  font-size: var(--font-size-xl);
}
.home-child4 {
  position: absolute;
  top: 2301px;
  left: 960px;
  border-radius: 50%;
  background-color: var(--color-chocolate-300);
  width: 901px;
  height: 901px;
}
.lorem-ipsum-is2 {
  position: absolute;
  top: 1282px;
  left: 164px;
  font-size: var(--font-size-xl);
  letter-spacing: 0.02em;
  display: inline-block;
  width: 518px;
  height: 90px;
}
.home-child5 {
  position: absolute;
  top: 4615px;
  left: -388px;
  border-radius: 50%;
  background-color: var(--color-chocolate-300);
  width: 901px;
  height: 901px;
}
.group-icon,
.home-child6 {
  position: absolute;
  top: 1251px;
  left: 1251px;
  width: 49px;
  height: 49px;
}
.home-child6 {
  top: 2767px;
  left: 1322px;
}
.home-child7,
.home-child8 {
  position: absolute;
  top: 1250.8px;
  left: 666.9px;
  width: 49.2px;
  height: 49.2px;
  object-fit: contain;
}
.home-child8 {
  top: 2766.8px;
  left: 51px;
}
.lorem-ipsum-is-container1 {
  top: 1663px;
  left: 770px;
  font-size: var(--font-size-25xl);
  line-height: 53px;
  font-weight: 600;
  width: 480px;
  height: 101px;
}
.about-us1,
.lorem-ipsum-is-container1,
.lorem-ipsum-is3,
.lorem-ipsum-is4 {
  position: absolute;
  letter-spacing: 0.02em;
  display: inline-block;
}
.about-us1 {
  top: 1625px;
  left: 770px;
  font-size: var(--font-size-5xl);
  text-transform: capitalize;
  font-weight: 600;
  width: 120px;
  height: 25px;
}
.lorem-ipsum-is3,
.lorem-ipsum-is4 {
  top: 0;
  left: 0;
  width: 534px;
  height: 90px;
}
.lorem-ipsum-is4 {
  top: 119px;
  left: 1px;
  width: 547px;
}
.lorem-ipsum-is-a-placeholder-t-parent {
  position: absolute;
  top: 1780px;
  left: 770px;
  width: 548px;
  height: 209px;
  font-size: var(--font-size-xl);
}
.home-child9 {
  position: absolute;
  top: 1501px;
  left: -404px;
  border-radius: 50%;
  background-color: var(--color-chocolate-300);
  width: 901px;
  height: 901px;
}
.home-child10,
.rectangle-icon {
  position: absolute;
  border-radius: var(--br-3xs);
}
.rectangle-icon {
  top: 1610px;
  left: 156px;
  width: 555px;
  height: 495px;
  object-fit: cover;
}
.home-child10 {
  top: 2011px;
  left: 985.5px;
  background-color: var(--color-chocolate-200);
  width: 214.5px;
  height: 50px;
  transform: rotate(180deg);
  transform-origin: 0 0;
}
.learn-more-group {
  position: absolute;
  top: 2025px;
  left: 820.5px;
  width: 116px;
  height: 22px;
  text-align: center;
  font-size: var(--font-size-mini);
  color: var(--color-white);
}
.group-child14 {
  position: absolute;
  top: 0;
  left: 0;
  width: 68px;
  height: 68px;
}
.group-child15,
.group-child16,
.group-child17,
.group-parent1 {
  position: absolute;
  top: 0;
  left: 472.4px;
  width: 68px;
  height: 68px;
}
.group-child16,
.group-child17,
.group-parent1 {
  left: 157.5px;
}
.group-child17,
.group-parent1 {
  left: 314.9px;
}
.group-parent1 {
  top: 2191px;
  left: 450px;
  width: 540.4px;
}
.our-services {
  position: absolute;
  top: 2460px;
  left: calc(50% - 145px);
  font-size: var(--font-size-25xl);
  line-height: 135%;
  text-transform: capitalize;
  font-weight: 600;
  white-space: pre-wrap;
  text-align: center;
}
.group-child18 {
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 33px rgba(0, 0, 0, 0.15);
  border-radius: var(--br-xl);
  background-color: var(--color-white);
  width: 378px;
  height: 438px;
}
.lorem-ipsum2,
.simply-dummy-text {
  position: absolute;
  text-transform: capitalize;
}
.simply-dummy-text {
  top: 305px;
  left: 21px;
  text-align: left;
  display: inline-block;
  width: 349.5px;
  height: 73px;
}
.lorem-ipsum2 {
  top: 274.9px;
  left: 20.8px;
  font-size: var(--font-size-xl);
  font-weight: 600;
}
.group-child19 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-3xs) var(--br-3xs) 0 0;
  width: 378.4px;
  height: 260.5px;
  object-fit: cover;
}
.group-child20 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-3xs);
  background-color: var(--color-chocolate-200);
  width: 118.8px;
  height: 29.7px;
}
.read-more {
  position: absolute;
  top: 5.1px;
  left: 10.2px;
  text-transform: capitalize;
  font-weight: 600;
  display: inline-block;
  width: 98.4px;
  height: 20.4px;
}
.rectangle-parent13 {
  position: absolute;
  top: 376px;
  left: 20.5px;
  width: 118.8px;
  height: 29.7px;
  font-size: var(--font-size-sm);
  color: var(--color-white);
}
.rectangle-parent12,
.rectangle-parent14,
.rectangle-parent16 {
  position: absolute;
  top: 2573px;
  left: 128px;
  width: 378.4px;
  height: 438px;
  text-align: center;
}
.rectangle-parent14,
.rectangle-parent16 {
  left: 522px;
}
.rectangle-parent16 {
  left: 916px;
}
.home-child11 {
  position: absolute;
  top: 3203px;
  left: 0;
  background-color: var(--color-whitesmoke-300);
  width: 1440px;
  height: 634px;
  display: none;
}
.client-review,
.lorem-ipsum-is-simply-container {
  position: absolute;
  top: 3336px;
  left: calc(50% - 303px);
  font-size: var(--font-size-mini);
  text-transform: capitalize;
  text-align: center;
}
.client-review {
  top: 3270px;
  left: calc(50% - 150px);
  font-size: var(--font-size-25xl);
  line-height: 135%;
  font-weight: 600;
}
.group-child27 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  width: 472px;
  height: 247px;
}
.ellipse-icon {
  position: absolute;
  top: 26px;
  left: 207px;
  border-radius: 50%;
  width: 59px;
  height: 59px;
  object-fit: cover;
}
.lorem-ipsum5,
.simply-dummy-text3 {
  position: absolute;
  text-transform: capitalize;
  display: inline-block;
}
.lorem-ipsum5 {
  top: 92px;
  left: 163px;
  font-weight: 500;
  width: 146px;
  height: 25px;
}
.simply-dummy-text3 {
  top: 152px;
  left: 74px;
  font-size: var(--font-size-xs);
  width: 325px;
  height: 54px;
}
.group-child28 {
  position: absolute;
  height: 6.88%;
  width: 24.92%;
  top: 48.18%;
  right: 37.58%;
  bottom: 44.94%;
  left: 37.5%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.rectangle-parent18,
.rectangle-parent19,
.rectangle-parent20 {
  position: absolute;
  top: 3427px;
  left: -18px;
  box-shadow: 0 0 33px rgba(0, 0, 0, 0.05);
  width: 472px;
  height: 247px;
  text-align: center;
  font-size: var(--heading-8-size);
}
.rectangle-parent19,
.rectangle-parent20 {
  left: 482px;
}
.rectangle-parent20 {
  left: 982px;
}
.pngwingcom-1-icon,
.pngwingcom-8-1,
.pngwingcom-9-1 {
  position: absolute;
  top: 18px;
  left: 117px;
  width: 113px;
  height: 65px;
  object-fit: cover;
}
.pngwingcom-8-1,
.pngwingcom-9-1 {
  top: 0;
  left: 0;
  width: 101px;
  height: 101px;
}
.pngwingcom-9-1 {
  top: 16px;
  left: 246px;
  width: 114px;
  height: 69px;
}
.pngwingcom-1-parent {
  position: absolute;
  top: 3697px;
  left: 540px;
  width: 360px;
  height: 101px;
}
.home-child12,
.rectangle-3-copy-3-1 {
  position: absolute;
  top: 3831px;
  left: 0;
  width: 1440px;
}
.rectangle-3-copy-3-1 {
  height: 470px;
  object-fit: cover;
}
.home-child12 {
  background-color: rgba(0, 0, 0, 0.2);
  height: 428px;
}
.airport-services,
.simply-dummy-text6 {
  position: absolute;
  color: var(--color-white);
  display: inline-block;
}
.airport-services {
  top: 3924px;
  left: calc(50% - 175px);
  font-size: var(--font-size-25xl);
  letter-spacing: 0.02em;
  font-weight: 600;
  font-family: var(--font-roboto);
  width: 347px;
  height: 45px;
}
.simply-dummy-text6 {
  top: 3988px;
  left: 436px;
  font-size: var(--font-size-mini);
  text-transform: capitalize;
  font-weight: 500;
  text-align: center;
  width: 569px;
  height: 62px;
}
.group-child35 {
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 19.6px rgba(0, 0, 0, 0.1);
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  width: 167px;
  height: 60px;
}
.layer-19-1 {
  position: absolute;
  top: 18px;
  left: 13px;
  width: 139px;
  height: 27px;
  object-fit: cover;
  mix-blend-mode: multiply;
}
.group-child36,
.rectangle-parent21 {
  position: absolute;
  top: 0;
  left: 372px;
  width: 167px;
  height: 60px;
}
.group-child36 {
  left: 0;
  box-shadow: 0 0 19.6px rgba(0, 0, 0, 0.1);
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  width: 166px;
}
.layer-23-1 {
  position: absolute;
  top: 5px;
  left: 55px;
  width: 57px;
  height: 48px;
  object-fit: cover;
  mix-blend-mode: multiply;
}
.layer-18-1,
.rectangle-parent22 {
  position: absolute;
  top: 0;
  left: 929px;
  width: 166px;
  height: 60px;
}
.layer-18-1 {
  top: 20px;
  left: 11px;
  width: 137px;
  height: 20px;
  object-fit: cover;
  mix-blend-mode: multiply;
}
.layer-22-1,
.rectangle-parent23 {
  position: absolute;
  top: 0;
  left: 186px;
  width: 167px;
  height: 60px;
}
.layer-22-1 {
  top: 13px;
  left: 28px;
  width: 110px;
  height: 34px;
  object-fit: cover;
  mix-blend-mode: multiply;
}
.layer-20-1,
.rectangle-parent24 {
  position: absolute;
  top: 0;
  left: 744px;
  width: 166px;
  height: 60px;
}
.layer-20-1 {
  top: 10px;
  left: 38px;
  width: 91px;
  height: 39px;
  object-fit: cover;
  mix-blend-mode: multiply;
}
.layer-17-1,
.rectangle-parent25 {
  position: absolute;
  top: 0;
  left: 558px;
  width: 167px;
  height: 60px;
}
.layer-17-1 {
  top: 11px;
  left: 17px;
  width: 134px;
  height: 33px;
  object-fit: cover;
  mix-blend-mode: multiply;
}
.group-parent2,
.home-child13,
.rectangle-parent26 {
  position: absolute;
  top: 0;
  left: 0;
  width: 167px;
  height: 60px;
}
.group-parent2,
.home-child13 {
  top: 4069px;
  left: 173px;
  width: 1095px;
}
.home-child13 {
  top: 4073px;
  left: 68px;
  width: 1312px;
  height: 49.2px;
}
.how-does-official-container {
  position: absolute;
  top: 4334px;
  left: calc(50% - 489px);
  font-size: 46px;
  line-height: 65px;
  font-weight: 600;
  text-align: center;
}
.group-child41 {
  position: absolute;
  top: 26px;
  left: 2px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-xl);
  background-color: var(--color-white);
  width: 1148px;
  height: 742px;
}
.group-child42 {
  position: absolute;
  top: 26px;
  left: 1px;
  border-radius: var(--br-xl) var(--br-xl) 0 0;
  background-color: var(--color-whitesmoke-300);
  width: 1149px;
  height: 94px;
}
.your-carding,
.your-own-ppc,
.your-print-adds {
  position: absolute;
  top: 61px;
  left: 169px;
  font-size: var(--font-size-mid);
  font-weight: 600;
  color: var(--color-darkslategray-200);
  display: inline-block;
  width: 163.9px;
  height: 24.4px;
}
.your-carding,
.your-print-adds {
  left: 618px;
  width: 117.8px;
}
.your-print-adds {
  left: 820.9px;
  width: 136.8px;
}
.group-child43 {
  position: absolute;
  top: 120px;
  left: 1px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  background-color: var(--color-white);
  width: 1147px;
  height: 90px;
}
.time-to-terminals {
  position: absolute;
  top: 0;
  left: 0;
  text-transform: capitalize;
  font-weight: 500;
  text-align: left;
}
.min-on-site,
.min-park,
.min-park1,
.minpark-ride {
  position: absolute;
  top: 0;
  left: 193px;
  text-transform: capitalize;
  font-weight: 500;
  display: inline-block;
  width: 158px;
}
.min-park,
.min-park1,
.minpark-ride {
  left: 433px;
  width: 132px;
}
.min-park1,
.minpark-ride {
  left: 647px;
  width: 142px;
}
.minpark-ride {
  left: 871px;
  width: 134px;
}
.time-to-terminals-parent {
  position: absolute;
  top: 156px;
  left: 72px;
  width: 1005px;
  height: 18px;
  text-align: center;
}
.group-child44 {
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  background-color: var(--color-white);
  width: 1147px;
  height: 90px;
}
.time-to-terminals-group {
  position: absolute;
  top: 36px;
  left: 71px;
  width: 1005px;
  height: 18px;
}
.rectangle-parent28 {
  position: absolute;
  top: 211px;
  left: 1px;
  width: 1147px;
  height: 90px;
  text-align: center;
}
.logo-design-01-1-icon1 {
  position: absolute;
  top: 0;
  left: 436px;
  width: 141px;
  height: 141px;
  overflow: hidden;
}
.group-child45 {
  position: absolute;
  top: 302px;
  left: 1px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  background-color: var(--color-white);
  width: 1147px;
  height: 90px;
}
.tick-circle-icon {
  position: absolute;
  top: 328px;
  left: 333px;
  width: 35px;
  height: 35px;
}
.close-circle-icon,
.tick-circle-icon1,
.tick-circle-icon2 {
  position: absolute;
  top: 330px;
  left: 766px;
  width: 35px;
  height: 35px;
}
.close-circle-icon,
.tick-circle-icon2 {
  top: 331px;
  left: 992px;
}
.close-circle-icon {
  top: 328px;
  left: 542px;
}
.loyalty-and-email {
  position: absolute;
  top: 0;
  left: 0;
  text-transform: capitalize;
  font-weight: 500;
}
.loyalty-and-email-deals-wrapper {
  position: absolute;
  top: 338px;
  left: 72px;
  width: 134px;
  height: 15px;
  font-family: var(--font-plus-jakarta-sans);
}
.tick-circle-icon3 {
  position: absolute;
  top: 26px;
  left: 332px;
  width: 35px;
  height: 35px;
}
.close-circle-icon1,
.tick-circle-icon4,
.tick-circle-icon5 {
  position: absolute;
  top: 28px;
  left: 765px;
  width: 35px;
  height: 35px;
}
.close-circle-icon1,
.tick-circle-icon5 {
  top: 29px;
  left: 991px;
}
.close-circle-icon1 {
  top: 26px;
  left: 541px;
}
.loyalty-and-email-deals-container {
  position: absolute;
  top: 36px;
  left: 71px;
  width: 134px;
  height: 15px;
}
.rectangle-parent29,
.rectangle-parent30,
.rectangle-parent31 {
  position: absolute;
  top: 393px;
  left: 1px;
  width: 1147px;
  height: 90px;
  font-family: var(--font-plus-jakarta-sans);
}
.rectangle-parent30,
.rectangle-parent31 {
  top: 484px;
  left: 0;
}
.rectangle-parent31 {
  top: 575px;
}
.group-child49,
.rectangle-parent32 {
  position: absolute;
  left: 0;
  width: 1147px;
  height: 102px;
}
.group-child49 {
  top: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0 0 var(--br-3xs) var(--br-3xs);
  background-color: var(--color-white);
}
.rectangle-parent32 {
  top: 666px;
  font-family: var(--font-plus-jakarta-sans);
}
.rectangle-parent27 {
  position: absolute;
  top: 4489px;
  left: 145px;
  width: 1150px;
  height: 768px;
  font-size: var(--font-size-xs);
  color: var(--color-gray-500);
}
.blogs1,
.lorem-ipsum-is-simply-container1 {
  position: absolute;
  top: 5427px;
  left: calc(50% - 303px);
  font-size: var(--font-size-mini);
  text-transform: capitalize;
  text-align: center;
}
.blogs1 {
  top: 5361px;
  left: calc(50% - 61px);
  font-size: var(--font-size-25xl);
  line-height: 135%;
  font-weight: 600;
}
.group-child50 {
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 33px rgba(0, 0, 0, 0.15);
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  width: 378px;
  height: 438px;
}
.lorem-ipsum8,
.simply-dummy-text7 {
  position: absolute;
  left: 16px;
  text-transform: capitalize;
}
.simply-dummy-text7 {
  top: 299px;
  text-align: left;
  display: inline-block;
  width: 349.5px;
  height: 73px;
}
.lorem-ipsum8 {
  top: 266px;
  font-size: var(--font-size-xl);
  font-weight: 600;
}
.group-child51 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-3xs) var(--br-3xs) 0 0;
  width: 378px;
  height: 236px;
  object-fit: cover;
}
.rectangle-parent34 {
  position: absolute;
  top: 380px;
  left: 19.5px;
  width: 118.8px;
  height: 29.7px;
  font-size: var(--font-size-sm);
  color: var(--color-white);
}
.div,
.rectangle-parent33 {
  position: absolute;
  top: 0;
  left: 0;
  width: 378px;
  height: 438px;
}
.div {
  letter-spacing: 0.02em;
  text-transform: capitalize;
  font-weight: 500;
  display: inline-block;
  width: 22.1px;
  height: 17.3px;
}
.group-child53 {
  position: absolute;
  height: 61.27%;
  width: 67.23%;
  top: 11.56%;
  right: 0;
  bottom: 27.17%;
  left: 32.77%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.line-div {
  position: absolute;
  top: 3px;
  left: 24px;
  border-right: 0.5px solid var(--color-gray-100);
  box-sizing: border-box;
  width: 0.5px;
  height: 10.1px;
}
.div1,
.parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 94.6px;
  height: 17.3px;
}
.div1 {
  top: 7px;
  left: 280px;
  font-size: var(--font-size-2xs);
  letter-spacing: 0.02em;
  text-transform: capitalize;
  display: inline-block;
  width: 71px;
  height: 14px;
}
.group-parent4 {
  position: absolute;
  top: 243px;
  left: 16px;
  width: 351px;
  height: 21px;
  font-size: var(--font-size-3xs);
}
.group-parent3,
.group-parent5,
.group-parent7 {
  position: absolute;
  top: 5517px;
  left: 147px;
  width: 378px;
  height: 438px;
  text-align: center;
}
.group-parent5,
.group-parent7 {
  left: 543px;
}
.group-parent7 {
  left: 939px;
}
.home-child14 {
  position: absolute;
  top: 6114px;
  left: 0;
  background-color: var(--color-whitesmoke-300);
  width: 1440px;
  height: 670px;
  display: none;
}
.contact-us1,
.lorem-ipsum-is-simply2 {
  position: absolute;
  left: calc(50% - 573px);
  text-transform: capitalize;
}
.lorem-ipsum-is-simply2 {
  top: 6409px;
  font-size: var(--font-size-mini);
  display: inline-block;
  width: 440px;
}
.contact-us1 {
  top: 6342px;
  font-size: var(--font-size-25xl);
  line-height: 135%;
  font-weight: 600;
  text-align: center;
}
.group-child64 {
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 36px rgba(0, 0, 0, 0.05);
  border-radius: var(--br-xl);
  background: linear-gradient(130.59deg, #fdebe2, #f26922);
  width: 571px;
  height: 528px;
}
.send-a-message {
  position: absolute;
  top: 32px;
  left: 178px;
  font-size: var(--font-size-6xl);
  letter-spacing: 0.02em;
  font-weight: 600;
  color: var(--color-black);
}
.group-child65 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-3xs);
  background-color: var(--color-whitesmoke-400);
  width: 439.8px;
  height: 46.5px;
}
.full-name {
  position: absolute;
  top: 12.6px;
  left: 27.2px;
  letter-spacing: 0.02em;
  display: inline-block;
  width: 92px;
  height: 24.2px;
}
.email-address,
.rectangle-parent40 {
  position: absolute;
  top: 94px;
  left: 66px;
  width: 439.8px;
  height: 46.5px;
}
.email-address {
  top: 12.7px;
  left: 27.2px;
  letter-spacing: 0.02em;
  display: inline-block;
  width: 132px;
  height: 24.2px;
}
.phone,
.rectangle-parent41 {
  position: absolute;
  top: 152.1px;
  left: 66px;
  width: 439.8px;
  height: 46.5px;
}
.phone {
  top: 12.6px;
  left: 27.2px;
  letter-spacing: 0.02em;
  display: inline-block;
  width: 58px;
  height: 24.2px;
}
.group-child68,
.rectangle-parent42 {
  position: absolute;
  top: 210.3px;
  left: 66px;
  width: 439.8px;
  height: 46.5px;
}
.group-child68 {
  top: 0;
  left: 0;
  border-radius: var(--br-3xs);
  background-color: var(--color-whitesmoke-400);
  height: 139.6px;
}
.message {
  position: absolute;
  top: 19.8px;
  left: 27px;
  letter-spacing: 0.02em;
  display: inline-block;
  width: 132px;
  height: 24.2px;
}
.group-child69,
.rectangle-parent43 {
  position: absolute;
  top: 268.4px;
  left: 66px;
  width: 439.8px;
  height: 139.6px;
}
.group-child69 {
  top: 430px;
  border-radius: var(--br-3xs);
  background-color: var(--color-black);
  width: 439px;
  height: 54px;
}
.submit {
  position: absolute;
  top: 445px;
  left: 244px;
  font-size: var(--font-size-lgi);
  letter-spacing: 0.02em;
  font-weight: 500;
  color: var(--color-white);
  display: inline-block;
  width: 83px;
  height: 23px;
}
.rectangle-parent39 {
  position: absolute;
  top: 6185px;
  left: 752px;
  width: 571px;
  height: 528px;
  font-size: var(--font-size-sm);
  color: var(--color-gray-1000);
}
.infoheathrowbudgetparkingcom {
  position: absolute;
  top: 3px;
  left: 43px;
  line-height: 28px;
  text-transform: capitalize;
}
.group-child70 {
  position: absolute;
  top: 0;
  left: 0;
  width: 28px;
  height: 28px;
}
.infoheathrowbudgetparkingcom-parent {
  position: absolute;
  top: 6512px;
  left: 331px;
  width: 273px;
  height: 31px;
}
.div6 {
  position: absolute;
  top: 4px;
  left: 43px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}
.group-child71,
.home-child15,
.parent1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 29px;
  height: 29px;
}
.home-child15,
.parent1 {
  top: 6513px;
  left: 149px;
  width: 149px;
}
.home-child15 {
  top: 6785px;
  left: 0;
  background-color: var(--color-black);
  width: 1440px;
  height: 600px;
}
.working-hours {
  font-weight: 500;
}
.copyright-heathrow-budget,
.working-hours-container {
  position: absolute;
  letter-spacing: 0.02em;
  line-height: 36px;
  font-family: var(--font-roboto);
  color: var(--color-white);
}
.working-hours-container {
  top: 7335px;
  left: 148px;
  font-size: var(--font-size-xs);
}
.copyright-heathrow-budget {
  top: 7336px;
  left: calc(50% - 189px);
  text-transform: capitalize;
}
.layer-14-1 {
  position: absolute;
  top: 6966px;
  left: 1098px;
  width: 240px;
  height: 33px;
  object-fit: cover;
}
.about-us2,
.blog,
.contact-us2,
.faqs,
.privacy-policy,
.terms-condition {
  position: absolute;
  top: 123px;
  left: 1px;
  letter-spacing: 0.02em;
  line-height: 36px;
  text-transform: capitalize;
}
.about-us2,
.blog,
.contact-us2,
.faqs,
.terms-condition {
  top: 82px;
}
.about-us2,
.blog,
.faqs,
.terms-condition {
  top: 41px;
}
.about-us2,
.faqs,
.terms-condition {
  top: 0;
  left: 2px;
}
.faqs,
.terms-condition {
  top: 164px;
  left: 0;
}
.faqs {
  top: 205px;
  left: 1px;
}
.cabbx-is-a,
.privacy-policy-parent {
  position: absolute;
  color: var(--color-white);
}
.privacy-policy-parent {
  top: 6905px;
  left: 824px;
  width: 134px;
  height: 241px;
  font-size: var(--font-size-sm);
}
.cabbx-is-a {
  top: 6924px;
  left: 145px;
  font-size: var(--font-size-mini);
  letter-spacing: 0.02em;
  line-height: 26px;
  font-family: var(--font-roboto);
  display: inline-block;
  width: 349px;
}
.logo-design-01-2-icon {
  position: absolute;
  top: 6789px;
  left: 114px;
  width: 177px;
  height: 177px;
  overflow: hidden;
}
.airpot,
.important-reading,
.newsletter {
  position: absolute;
  top: 6861px;
  left: 1098px;
  font-size: var(--font-size-lg);
  letter-spacing: 0.02em;
  font-weight: 500;
  color: var(--color-white);
}
.airpot,
.important-reading {
  top: 6857px;
  left: 570px;
}
.important-reading {
  top: 6861px;
  left: 824px;
  line-height: 30px;
}
.vector-icon2,
.vector-icon3 {
  position: absolute;
  height: 4.17%;
  width: 10.7%;
  top: 2.78%;
  right: 89.3%;
  bottom: 93.06%;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon3 {
  top: 13.06%;
  bottom: 82.78%;
}
.vector-icon10,
.vector-icon11,
.vector-icon4,
.vector-icon5,
.vector-icon6,
.vector-icon7,
.vector-icon8,
.vector-icon9 {
  position: absolute;
  height: 4.17%;
  width: 10.7%;
  top: 22.78%;
  right: 89.3%;
  bottom: 73.06%;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon10,
.vector-icon11,
.vector-icon5,
.vector-icon6,
.vector-icon7,
.vector-icon8,
.vector-icon9 {
  top: 32.78%;
  bottom: 63.06%;
}
.vector-icon10,
.vector-icon11,
.vector-icon6,
.vector-icon7,
.vector-icon8,
.vector-icon9 {
  top: 43.06%;
  bottom: 52.78%;
}
.vector-icon10,
.vector-icon11,
.vector-icon7,
.vector-icon8,
.vector-icon9 {
  top: 52.78%;
  bottom: 43.06%;
}
.vector-icon10,
.vector-icon11,
.vector-icon8,
.vector-icon9 {
  top: 62.78%;
  bottom: 33.06%;
}
.vector-icon10,
.vector-icon11,
.vector-icon9 {
  top: 72.78%;
  bottom: 23.06%;
}
.vector-icon10,
.vector-icon11 {
  top: 82.78%;
  bottom: 13.06%;
}
.vector-icon11 {
  top: 92.78%;
  bottom: 3.06%;
}
.gatwick-airport-heathrow-container {
  position: absolute;
  top: 0;
  left: 31px;
  letter-spacing: 0.02em;
  line-height: 36px;
}
.vector-parent {
  position: absolute;
  height: 4.87%;
  width: 10.97%;
  top: 93.46%;
  right: 49.79%;
  bottom: 1.67%;
  left: 39.24%;
  font-size: var(--font-size-sm);
  color: var(--color-white);
  font-family: var(--font-roboto);
}
.home-child16 {
  position: absolute;
  top: 6902px;
  left: 1098px;
  border-radius: var(--br-xl);
  background-color: var(--color-white);
  width: 263px;
  height: 35px;
}
.home-child17 {
  position: absolute;
  top: 6904px;
  left: 1327px;
  border-radius: 50%;
  background-color: var(--color-chocolate-200);
  width: 30px;
  height: 30px;
}
.material-symbolssend-icon {
  position: absolute;
  top: 6906.9px;
  left: 1331.9px;
  width: 23.2px;
  height: 23.2px;
  overflow: hidden;
  object-fit: contain;
}
.email-address1 {
  position: absolute;
  top: 6901px;
  left: 1115px;
  font-size: var(--font-size-sm);
  letter-spacing: 0.02em;
  line-height: 36px;
  text-transform: capitalize;
  color: var(--color-darkslategray-200);
}
.home-child18 {
  position: absolute;
  top: 7326px;
  left: calc(50% - 587px);
  background-color: var(--color-gainsboro-300);
  width: 1175px;
  height: 1px;
}
.group-child72,
.group-child73 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-15xl) 0 0 var(--br-15xl);
  background-color: var(--color-mediumspringgreen);
  width: 100px;
  height: 68px;
}
.group-child73 {
  top: 80px;
  background-color: var(--color-darkorange);
}
.layer-65-1,
.layer-67-1 {
  position: absolute;
  top: 10px;
  left: 33px;
  width: 48px;
  height: 49px;
  object-fit: cover;
}
.layer-67-1 {
  top: 92px;
  left: 35px;
  width: 43px;
  height: 44px;
}
.home-child19,
.rectangle-parent44 {
  position: absolute;
  top: 306px;
  left: 1340px;
  width: 100px;
  height: 148px;
}
.home-child19 {
  height: 0.2%;
  width: 7.77%;
  top: 95.23%;
  right: 82.16%;
  bottom: 4.56%;
  left: 10.07%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.home {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  height: 7385px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-smi);
  color: var(--color-black);
  font-family: var(--heading-8);
}
